//==============================================================================
// Buybox Kit Selector
//
// Displays steps with product options that lead to the corresponding product
// page.
//==============================================================================
import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { getCatalogId } from '@msdyn365-commerce/core';
import { ProductSearchResult, ProductSearchCriteria } from '@msdyn365-commerce/retail-proxy';
import { searchByCriteriaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';

import { clsHelper } from '../../utilities/class-name-helper';
import { attrNames } from '../../utilities/global-constants';
import { convertProductAttributes, AttributesWithMetadata } from '../../utilities/data-attribute-parser';

import { IBuyboxKitSelectorData } from './buybox-kit-selector.data';
import { IBuyboxKitSelectorProps } from './buybox-kit-selector.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
export interface SelectorMap {
    format: string;
    itemId: string;
    productName?: string;
    productPath?: string;
}

export interface OptionObject {
    id: string;
    label: string;
}

export interface StepObject {
    label: string;
    options: OptionObject[];
}

export const enum KitFormat {
    onlineText = 'ONLINE-TEXT',     // Online with textbooks and tests
    dvdText = 'DVD-TEXT',           // DVD with textbooks and tests
    online = 'ONLINE',              // Online without textbooks and tests
    dvd = 'DVD',                    // DVD without textbooks and tests
    text = 'TEXT',                  // Textbooks
    complete = 'COMPLETE',          // Complete kit (student + teacher)
    student = 'STUDENT',            // Student
    teacher = 'TEACHER'             // Teacher
}

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'buybox-kit-selector';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * BuyboxKitSelector component
 * @extends {React.Component<IBuyboxKitSelectorProps<IBuyboxKitSelectorData>>}
 */
//==============================================================================
@observer
class BuyboxKitSelector extends React.Component<IBuyboxKitSelectorProps<IBuyboxKitSelectorData>> {
    //==========================================================================
    // VARIABLES
    //==========================================================================
    @observable private productList: SelectorMap[] = [];
    private readonly labelFormat = this.props.config.labelFormat;
    private readonly labelTextInclude = this.props.config.labelTextInclude;
    private readonly labelTextType = this.props.config.labelTextType;
    private readonly resources = this.props.resources;
    private readonly optionsMap = {
        format: {
            text: [
                { id: KitFormat.onlineText, label: this.resources.buyboxKitSelector_optionOnline },
                { id: KitFormat.dvdText, label: this.resources.buyboxKitSelector_optionDvd },
                { id: KitFormat.text, label: this.resources.buyboxKitSelector_optionTextbooks }
            ],
            noText: [
                { id: KitFormat.online, label: this.resources.buyboxKitSelector_optionOnline },
                { id: KitFormat.dvd, label: this.resources.buyboxKitSelector_optionDvd },
                { id: KitFormat.text, label: this.resources.buyboxKitSelector_optionTextbooks }
            ]
        },
        text: {
            online: [
                { id: KitFormat.onlineText, label: this.resources.buyboxKitSelector_optionTextInclude },
                { id: KitFormat.online, label: this.resources.buyboxKitSelector_optionTextNoInclude }
            ],
            dvd: [
                { id: KitFormat.dvdText, label: this.resources.buyboxKitSelector_optionTextInclude },
                { id: KitFormat.dvd, label: this.resources.buyboxKitSelector_optionTextNoInclude }
            ],
            textbook: [
                { id: KitFormat.complete, label: this.resources.buyboxKitSelector_optionComplete },
                { id: KitFormat.student, label: this.resources.buyboxKitSelector_optionStudent },
                { id: KitFormat.teacher, label: this.resources.buyboxKitSelector_optionTeacher }
            ]
        }
    };
    private readonly stepMap = {
        [KitFormat.onlineText]: [
            { label: this.labelFormat, options: this.optionsMap.format.text },
            { label: this.labelTextInclude, options: this.optionsMap.text.online }
        ],
        [KitFormat.online]: [
            { label: this.labelFormat, options: this.optionsMap.format.noText },
            { label: this.labelTextInclude, options: this.optionsMap.text.online }
        ],
        [KitFormat.dvdText]: [
            { label: this.labelFormat, options: this.optionsMap.format.text },
            { label: this.labelTextInclude, options: this.optionsMap.text.dvd }
        ],
        [KitFormat.dvd]: [
            { label: this.labelFormat, options: this.optionsMap.format.noText },
            { label: this.labelTextInclude, options: this.optionsMap.text.dvd }
        ],
        [KitFormat.text]: [
            { label: this.labelFormat, options: this.optionsMap.format.text },
            { label: this.labelTextType, options: this.optionsMap.text.textbook }
        ],
        [KitFormat.complete]: [
            { label: this.labelFormat, options: this.optionsMap.format.text },
            { label: this.labelTextType, options: this.optionsMap.text.textbook }
        ],
        [KitFormat.student]: [
            { label: this.labelFormat, options: this.optionsMap.format.text },
            { label: this.labelTextType, options: this.optionsMap.text.textbook }
        ],
        [KitFormat.teacher]: [
            { label: this.labelFormat, options: this.optionsMap.format.text },
            { label: this.labelTextType, options: this.optionsMap.text.textbook }
        ]
    };

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Invoked immediately after component is mounted
    //------------------------------------------------------
    public componentDidMount(): void {
        this._getProductList(attrNames.kitFormats);
    }

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { config, data } = this.props;
        const itemId = data.product.result?.ItemId;

        // If product list or item ID cannot be obtained, do not render module
        if (!this.productList.length || !itemId) {
            return null;
        }

        const selectedFormat = this._findSelectedFormat(this.productList, itemId);

        return (
            <div className={classnames(BASE_CLASS, config.className)}>
                {selectedFormat && this._renderSteps(selectedFormat)}
            </div>
        );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Render steps
    //------------------------------------------------------
    private readonly _renderSteps = (selectedFormat: SelectorMap): JSX.Element[] => {
        return this.stepMap[selectedFormat.format].map((step: StepObject, index: number) => {
            return (
                <React.Fragment key={index}>
                    {this._renderStep(step, index, selectedFormat)}
                </React.Fragment>
            );
        });
    };

    //------------------------------------------------------
    // Render each step
    //------------------------------------------------------
    private readonly _renderStep = (step: StepObject, index: number, selectedFormat: SelectorMap): JSX.Element => {
        return (
            <div className={cls('step')}>
                {this._renderStepHeading(step, index)}
                {this._renderStepOptions(step, selectedFormat)}
            </div>
        );
    };

    //------------------------------------------------------
    // Render step heading
    //------------------------------------------------------
    private readonly _renderStepHeading = (step: StepObject, index: number): JSX.Element | null => {
        // Only return step label if set up in config
        if (step.label) {
            const { resources } = this.props;

            return (
                <div className={cls('step-heading')}>
                    <span className={cls('step-number')}>
                        {resources.buyboxKitSelector_stepLabel} {String(index + 1)}:
                    </span>
                    <span className={cls('step-label')}>
                        {step.label}
                    </span>
                </div>
            );
        }

        return null;
    };

    //------------------------------------------------------
    // Render step options
    //------------------------------------------------------
    private readonly _renderStepOptions = (step: StepObject, selectedFormat: SelectorMap): JSX.Element => {
        return (
            <div className={cls('options')}>
                {step.options.map((option: OptionObject, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            {this._renderStepOption(option, selectedFormat)}
                        </React.Fragment>
                    );
                })}
            </div>
        );
    };

    //------------------------------------------------------
    // Render each option for step
    //------------------------------------------------------
    private readonly _renderStepOption = (option: OptionObject, selectedFormat: SelectorMap): JSX.Element | null => {

        // added textbook only support
        let optionId = option.id;
        if (option.id === KitFormat.text) {
            if (selectedFormat.format === KitFormat.student || selectedFormat.format === KitFormat.teacher || selectedFormat.format === KitFormat.complete) {
                optionId = selectedFormat.format;
            } else {
                optionId = KitFormat.complete;
            }
        }
        const optionItem = this.productList.find(product => product.format === optionId);
        const optionName = optionItem?.productName;
        const optionPath = optionItem?.productPath;

        // Only render option link if item path for it exists
        if (optionPath) {
            const isActive = selectedFormat.format === optionId;

            return (
                <a
                    className={classnames(cls('option'), {'active': isActive})}
                    href={optionPath}
                    aria-label={optionName}
                >
                    {option.label}
                </a>
            );
        }

        return null;
    };

    //------------------------------------------------------
    // Find selected format from item ID
    //------------------------------------------------------
    private readonly _findSelectedFormat = (convertedData: SelectorMap[], itemId: string): SelectorMap | undefined => {
        return convertedData.find(format => format.itemId === itemId);
    };

    //------------------------------------------------------
    // Parse kit selector data into a friendlier format
    //
    // Ex input data: 'ONLINE-TEXT:004000b|ONLINE:004000d|
    //                 DVD-TEXT:004000c|DVD:004000e'
    //------------------------------------------------------
    private readonly _parseSelectorData = (selectorData: string): SelectorMap[] => {
        return selectorData.split('|').map(property => {
            const splitData = property.split(':');
            return {
                format: splitData[0],
                itemId: splitData[splitData.length - 1]
            };
        });
    };

    //------------------------------------------------------
    // Get converted product attributes
    //------------------------------------------------------
    private readonly _getConvertedAttributes = (): AttributesWithMetadata | undefined => {
        const { data } = this.props;
        const productAttributes = data.productSpecificationData?.result;
        return productAttributes && convertProductAttributes(productAttributes);
    };

    //------------------------------------------------------
    // Get product list with parsed properties
    //------------------------------------------------------
    private readonly _getProductList = async (attribute: string): Promise<void> => {
        const convertedAttributes = this._getConvertedAttributes();
        const kitAttribute = convertedAttributes && convertedAttributes[attribute] as string;
        const parsedAttributes = kitAttribute && this._parseSelectorData(kitAttribute);

        const productItemIds = parsedAttributes && parsedAttributes.map(item => item.itemId);
        const productList = productItemIds && await this._getProducts(productItemIds);

        const productResult = parsedAttributes && productList && productList.map((item: ProductSearchResult) => {
            const product = parsedAttributes.find(product => product.itemId === item.ItemId);
            const format = product?.format!;
            const itemId = item.ItemId!;
            const productName = item.Name;
            const productPath = this._getProductPath(item);
            return {format, itemId, productName, productPath};
        });

        this.productList = productResult || [];
    };

    //------------------------------------------------------
    // Get products from item IDs
    //------------------------------------------------------
    private readonly _getProducts = async (itemIds: string[]): Promise<ProductSearchResult[]> => {
        const { context } = this.props;

        const itemList = itemIds?.map(itemId => ({ ItemId: itemId }));

        // Build a ProductSearchCriteria object
        const criteria: ProductSearchCriteria = {
            ItemIds: itemList,
            Context: {
                ChannelId: context.request.apiSettings.channelId,
                CatalogId: getCatalogId(context.request)
            }
        };

        try {
            // Fetch search results
            const searchResults = await searchByCriteriaAsync({ callerContext: context.actionContext }, criteria);
            return searchResults;
        } catch (error) {
            if (context.telemetry) {
                context.telemetry.exception(error as Error);
                context.telemetry.debug('Unable to find products');
            }
        }

        return [];
    };

    //------------------------------------------------------
    // Format product URL path from product rec ID
    //------------------------------------------------------
    private readonly _getProductPath = (product: ProductSearchResult): string | undefined => {
        return `/${String(product.RecordId)}.p`;
    };
}

export default BuyboxKitSelector;
